body{
  position: relative;
}
@media all and (max-width:767px) {
  html{font-size: 62.5%;}
  body{font-size: 1.4rem;}
}
.navbar{
  .navbar-brand img{
    margin: 0 auto;
    display: block;
  }
  .navbar-toggler{
    position: fixed;
    top: 15px;
    z-index: 9999;
    padding: .25rem .5rem;
    cursor: pointer;
    i{
      color: #fff;
      font-size: 3rem;
      font-weight: normal;
    }
  }
  ul{
    position: fixed;
    z-index: 9000;
  }

  @media all and (min-width:768px) {
    .navbar-toggler{
      display: none;
      opacity: 0;
    }
    #navbars ul.navbar-nav{
      right: 15px;
      top: 15px;
      li.nav-link{
       color: #717171;
      }
    }
  }
  @media all and (max-width:767px) {
    #navbars{
      opacity: 0;
      ul{
        top: 0;
        left: 0;
        right:0;
        height: 100vh;
        li{
          background: rgba(0,0,0,0.5);
          text-align: center;
          a.nav-link{
            color: #fff;
            padding: 15px;
            &:hover{
              background: rgba(0,0,0,0.6);
            }
          }
        }
      }
      &.show{
        opacity: 1;
        transition: all .5s;
      }
    }
  }
}
#footer{
  background: #444;
  color: #fff;
}

.to-page-top {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  z-index: 9999;
  a {
      text-decoration: none;
      display: block;
      font-size: 30px;
      text-align: center;
      padding-top: 8px;
      color: #555;
  }
}


.link-fill{
  border: 1px solid #444;
  background: #444;
  padding: .9rem 2rem;
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  text-align:center;
  &:hover{
    background: #fff;
    color: #444;
    text-decoration: none;
  }
  i{
    font-size: 120%;
    padding-right: .3rem
  }
}

// home
body.home{
  .navbar{
    .sitename{
      min-width: 100%;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 1;
      img{
        width: 162px;
        padding-bottom: 60px;
        box-sizing: border-box;
      }
    }
  }
  #feature{
    height: 100vh;
  }
  div#contents{
    // margin-top: 100vh;
    position: relative;
    // background: #fff;
    h2{
      line-height: 1.4;
      font-weight: bold;
    }
    p{
      line-height: 1.8;
    }
    #shop{
      background: #f3f1ee;
      .shop{
        position: relative;
        padding:0 2%;
      }
      @media all and (max-width:767px) {
        .shop + .shop{
          margin-top: 1rem;
          padding-top: 3rem;
          border-top:1px solid #fff;
        }
      }
      a{
        height: 100%;
        padding:0 0 3.6rem;
        display: block;
        h2{font-size: 16px;}
        h3{font-size: 12px;}
        p{font-size: 14px;}
        z-index: 100;
        .link-fill{
          text-align: center;
          position: absolute;
          box-sizing: border-box;
          bottom:0;
          z-index:1;
          cursor: pointer;
          @media all and (min-width:768px) {
            width: 88%;
          }
          @media all and (max-width:767px) {
            width: 96%;
          }
        }
      }
      a:hover{
        text-decoration: none;
        img{
          opacity: .8;
          transition: all .5s;
        }
        .link-fill{
          background: #fff;
          color: #444;
        }
      }
      @media all and (min-width:768px) {
        .row{
          border-left:1px solid #fff;
        }
        .col-10{
          border-right:1px solid #fff;
          margin-bottom: 0 !important;
        }
      }
    }
    #recruit{
      .photos-slider img {
        height: 360px;
      }
      @media all and (max-width:767px) {
        .photos-slider img {
          height: 240px;
        }
      }
    }
    #outline{
      background: #5AB6E1;
      img{
        width: 105px;
      }
      table{
        border-bottom: 1px solid #acdbf0;
        tr{

        }
        th, td{
          font-weight: normal;
          border-color:#acdbf0;
        }
      }
    }
  }
}
